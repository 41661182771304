import request from "./request";

//存证

//开始批量存证查询任务
export function batchEvidenceParse() {
  return request({
    url: "/evidenceparsequeue/batchEvidenceParse",
    method: "get",
  });
}

//查询批量存证任务
export function findEvidenceParseQueue(data) {
  return request({
    url: "/evidenceparsequeue/findEvidenceParseQueue",
    method: "get",
    data,
  });
}

//清空当前批量队列
export function delEvidenceParseQueue(){
  return request({
    url : "/uploader/delEvidenceParseQueue",
    method : "post"
  });
}

//文字存证 -- 直接添加订单调起支付
export function addStr(data) {
  return request({
    url: "/existevidence/addstr",
    method: "post",
    data,
  });
}

//web存证
export function addWeb(data) {
  return request({
    url: "/existevidence/addweb",
    method: "post",
    data,
  });
}

//分页查询历史存证列表
export function findStoreHistoryList(data) {
  return request({
    url: "/existevidence/page",
    method: "post",
    data,
  });
}

//删除历史存证文件
export function delExistEvidence(data){
  return request({
    url : "/existevidence/delExistEvidence",
    method : "post",
    data
  });
}

//批量存证新增订单-获取订单数据
export function addOrder(data) {
  return request({
    url: "otherorder/add",
    method: "post",
    data,
  });
}

//批量存证-调起支付-获取二维码
export function orderPay(data) {
  return request({
    url: "/pay/otherOrderPay",
    method: "post",
    data,
  });
}

//查询微信二维码扫码状态
export function queryWechatQrCode(data) {
  return request({
    url: "/pay/wechatOtherOrderQueryQrcode",
    method: "post",
    data,
  });
}

//打包存证文件下载
export function zipPackDownload(data) {
  return request({
    url: "/existevidence/zipPackDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}

//完成文字存证 existevidence/trustedCTSRResult
export function trustedCTSRResult(data) {
  return request({
    url: "existevidence/trustedCTSRResult",
    method: "post",
    data,
  });
}

//添加网页存证 existevidence/trustedCTSRResult
export function trustedWebResult(data) {
  return request({
    url: "existevidence/trustedWebResult",
    method: "post",
    data,
  });
}
